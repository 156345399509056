import { ICreditPolicyCollateralResponse } from 'redux/reducers/lender/allState/types/state'

export const getRequiredCollateralIds = (collaterals: ICreditPolicyCollateralResponse[]) => {
  if (!collaterals.length) {
    return []
  }
  return collaterals
    .filter(collateral => collateral.required)
    .map(collateral => collateral.id)
}
