import { IRepaymentChangePrecalculate } from 'redux/reducers/client/allState/types/stateTypes'
import { paymentScheduleChangeActions } from 'redux/reducers/common/paymentScheduleChange/paymentScheduleChangeActions'
import {
  actionTypes,
  ISetIsLoadingPaymentSchedule,
  ISetPaymentPreCalculate
} from 'redux/reducers/common/paymentScheduleChange/types/actionTypes'

import { paymentScheduleChangeState } from './types/stateTypes'

const initialState: paymentScheduleChangeState = {
  paymentPreCalculateSchedule: [],
  maxSumPayment: null,
  prevDecreasePaymentDate: null,
  nextDecreasePaymentDate: null,
  currentHolidayPaymentDate: null,
  nextHolidayPaymentDate: null,
  maxExtensionPeriods: null,
  isLoading: false
}
const paymentScheduleChangeReducer = (state = initialState, action: actionTypes): paymentScheduleChangeState => {
  switch (action.type) {
    case paymentScheduleChangeActions.SET_PAYMENT_PRE_CALCULATE: {
      return {
        ...state,
        paymentPreCalculateSchedule: action.payload
      }
    }
    case paymentScheduleChangeActions.SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.payload
      }
    }
    default:
      return state
  }
}
// actions
export const setPaymentPreCalculateSchedule = (paymentPreCalculateSchedule: IRepaymentChangePrecalculate[]): ISetPaymentPreCalculate => ({
  type: paymentScheduleChangeActions.SET_PAYMENT_PRE_CALCULATE,
  payload: paymentPreCalculateSchedule
})

export const setIsLoadingPaymentSchedule = (isLoading: boolean): ISetIsLoadingPaymentSchedule => ({
  type: paymentScheduleChangeActions.SET_IS_LOADING,
  payload: isLoading
})

export default paymentScheduleChangeReducer
