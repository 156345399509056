import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  styled,
  Tooltip,
  Typography
} from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import BankIdLogo from 'assets/images/bankId.png'
import AppPreloader from 'components/common/preloaders/appPreloader'
import { currentUserSelector, userFlowSelector } from 'redux/reducers/auth/authSelectors'
import { updateAppFormUserFlowThunk } from 'redux/reducers/auth/authThunks'
import { setClientIsLoading, setClientToastMessage } from 'redux/reducers/client/allState/clientReducer'
import {
  appFormCreditPolicyData,
  clientIsLoadingSelector
} from 'redux/reducers/client/allState/clientSelectors'
import { applicationRiskScoreRequest } from 'redux/reducers/client/allState/clientThunks'
import { getErrorMessage } from 'utils/getErrorMessage'

import { fetchBankIdRequests } from './fetchBankIdRequests'
import { fetchBankIdUrl } from './fetchBankIdUrl'

const BankIdPage: FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const { appFormFlowData } = useSelector(userFlowSelector)
  const currentUser = useSelector(currentUserSelector)
  const creditPolicy = useSelector(appFormCreditPolicyData)
  const isLoading = useSelector(clientIsLoadingSelector)

  const [showErrorMassageBankId, setShowErrorMassageBankId] = useState(false)
  const [checkBankId, setCheckBankId] = useState(false)

  const handlePrev = useCallback(() => {
    if (appFormFlowData) {
      dispatch(updateAppFormUserFlowThunk({ step: appFormFlowData.flow_data.step - 1 }))
    }
  }, [appFormFlowData, dispatch])

  useEffect(() => {
    (async () => {
      if (!appFormFlowData) return
      const res = await fetchBankIdRequests(appFormFlowData?.flow_data.id)

      // @ts-ignore
      if ((res.status === 200 || res.status === 401) && (res.data?.customer_crypto === null || res.data?.customer_crypto?.length === 0) && (res.data?.state !== null || res.data?.state?.length === 0)) {
        setShowErrorMassageBankId(true)
      }

      // @ts-ignore
      if ((res.status === 200 || res.status === 401) && res?.data?.customer_crypto?.length > 0 && appFormFlowData?.flow_data?.step) {
        await dispatch(updateAppFormUserFlowThunk({ step: appFormFlowData?.flow_data?.step + 1 }))
      }
    })()
  }, [appFormFlowData?.flow_data.id, dispatch, appFormFlowData])

  if (isLoading) {
    return <Box mt={5} textAlign="center">
      <AppPreloader />
      {t('client.applicationForm.bankCard.pleaseWait')}
    </Box>
  }

  if (!appFormFlowData || !creditPolicy || !currentUser) return <Typography>{t('client.applicationForm.bankCard.noData')}</Typography>

  const {
    flow_data: { id: appId, step }
  } = appFormFlowData

  const onSubmit = async (data: any) => {
    try {
      dispatch(setClientIsLoading(true))
      if (appFormFlowData?.flow_data.id) {
        dispatch(applicationRiskScoreRequest(appFormFlowData?.flow_data.id))
      }
      await dispatch(updateAppFormUserFlowThunk({ step: step + 1 }))
    } catch (err: any) {
      dispatch(setClientIsLoading(false))
      dispatch(
        setClientToastMessage({ type: 'error', message: getErrorMessage(err.response.data) })
      )
    }
  }

  const handleBankIdClick = async () => {
    await fetchBankIdUrl(appId, 'application_id').then((r) => {
      if (r.error) {
        dispatch(setClientToastMessage({
          message: r.error,
          type: 'error'
        }))
      }

      if (r.url) {
        window.location.href = r.url
      }
    })
  }

  return (
    <>
      {showErrorMassageBankId && (
        <FlexBox>
          <Typography sx={{ color: 'red' }}>
            Виникла помилка при авторизації через BankID. Будь ласка, зв'яжіться з нами
          </Typography>
        </FlexBox>
      )}
      <form onSubmit={onSubmit}>
        <FlexBox>
          <Tooltip title="Для Ідентифікації натисніть на зображення BankID" placement="top" open={checkBankId} arrow>
            <ImageBox
              onClick={async () => {
                if (checkBankId) {
                  await handleBankIdClick()
                } else {
                  dispatch(setClientToastMessage({ type: 'error', message: t('formValidation.iban', { n: 50 }) }))
                }
              }}
            >
              <img src={BankIdLogo} alt={'Bank Id'} />
            </ImageBox>
          </Tooltip>
          <Typography>
            {t('client.applicationForm.bankCard.bankIdText')}
          </Typography>

          <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
            <Checkbox
              checked={checkBankId}
              onChange={() => setCheckBankId(prev => !prev)}
            />
            <Typography sx={{ maxWidth: '800px' }}>
              Даю згоду на обробку персональних даних отриманих через систему BankID НБУ. Передача даних з системи BankID НБУ безкоштовна. Перелік полів, які будуть передаватися зазначені нижче
            </Typography>
          </Box>

          <Box sx={{
            '.MuiAccordionSummary-gutters': { margin: '0 auto' }
          }}>
            <Accordion
              sx={{
                boxShadow: 'none !important',
                backgroundColor: '#fff !important',
                marginBottom: '0 !important',
                '& .MuiAccordionSummary-content': {
                  margin: '0 5px 0 0'
                }
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{ width: 'max-content' }}
              >
                Натисніть, щоб побачити перелік полів
              </AccordionSummary>
              <AccordionDetails sx={{ padding: '0 16px !important' }}>
                Прізвище.
                <br />
                Ім`я.
                <br />
                По-батькові.
                <br />
                Номер (и) контактного телефону.
                <br />
                Реєстраційний номер облікової картки платника податків, номер (та за наявності - серію) паспорта громадянина України, в якому проставлено відмітку про відмову від прийняття реєстраційного номера облікової картки платника податків, чи номер паспорта із записом про відмову від прийняття реєстраційного номера облікової картки платника податків в електронному безконтактному носії (для нерезидентів заповнюється за наявності).
                <br />
                Унікальний ідентифікатор особи (клієнта) в банку. У випадку якщо банк не має такого ідентифікатора, можливо вказати значення ключа inn або серію і номер паспорта.
                <br />
                Статичний текст з інформацією про надані дані Абонентом-ідентифікатором щодо особи, дата і час надання. “Інформація надана з використанням Системи BankID НБУ dd.mm.yyyy hh.mm”
                <br />
                Дата народження.
                <br />
                Місце народження (за наявності).
                <br />
                Громадянство.
                <br />
                Стать.
                <br />
                Адреса електронної пошти.
                <br />
                Соціальний статус.
                <br />
                Місце роботи.
                <br />
                Посада.
                <br />
                Ознака, чи визначена особа повіреною Абонентом-ідентифікатором такою, що належить до категорії PEPs (публічно значущі особи, члени сім’ї, пов’язані).
                <br />
                Ознака, чи визначена особа повіреною Абонентом-ідентифікатором такою, що включена до переліку осіб, пов’язаних зі здійсненням терористичної діяльності або щодо яких застосовано міжнародні санкції.
                <br />
                Ознака, чи визначена особа повіреною Абонентом-ідентифікатором такою, що включена до переліку осіб, щодо яких застосовані персональні, спеціальні економічні та інші обмежувальні заходи (санкції), санкції РНБОУ.
                <br />
                Ознака, чи присвоєно особі повіреною Абонентом-ідентифікатором (неприйнятно) високий рівень ризику ПВК/ФТ.
                <br />
                Ознака, чи визначена особа повіреною Абонентом-ідентифікатором такою, що є резидентом України.
                <br />
                Дата встановлення або дата зміни фінансового номеру телефону в системах Абонента-ідентифікатора.
                <br />
                Дата проходження ідентифікації особою або дата останнього уточнення інформації про нєї.
                <br />
                Тип адреси проживання: factual – фактична адреса проживання (місце перебування); juridical – адреса реєстрації (місце проживання).
                <br />
                Країна проживання/реєстрації.
                <br />
                Поштовий індекс.
                <br />
                Область.
                <br />
                Район.
                <br />
                Назва населеного пункту.
                <br />
                Тип вулиці (наприклад: вулиця, узвіз, проспект і тд) та її назва Якщо адреса користувача не передбачає наявності типу вулиці (наприклад: вулиця, узвіз, проспект і тд) та її назви, необхідно передавати значення 'n/a'.
                <br />
                Номер будинку (і за наявності літера будинку та/або номер корпусу/блоку/секції). Якщо адреса користувача не передбачає наявності номеру будинку, необхідно передавати значення 'n/a'.
                <br />
                Номер квартири (і за наявності літера квартири).
                <br />
                Тип документу: passport – паспорт громадянина України; idpassport – id-картка; zpassport – паспорт для виїзду за кордон; ident – інший документ, що посвідчує особу та відповідно до законодавства України може бути використаний на території України для укладення правочинів.
                <br />
                Назва документу
                <br />
                Серія документа (для типу idpassport – не заповнюється, для осіб - нерезидентів заповнюється за наявності серії в їх документах).
                <br />
                Номер документа
                <br />
                Яким органом видано документ
                <br />
                Дата видачі документу.
                <br />
                Дата закінчення строку дії (для типу passport - не заповнюється).
                <br />
                Унікальний номер запису в Єдиному державному демографічному реєстрі.
                <br />
                Країна видачі документа.
              </AccordionDetails>
            </Accordion>
          </Box>

        </FlexBox>
        <Box>
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: 2
          }}>
            <Button onClick={handlePrev} variant="contained" disabled={showErrorMassageBankId}>
              {t('client.applicationForm.bankCard.buttonPrev')}
            </Button>
          </Box>
        </Box>
      </form>
    </>
  )
}

const FlexBox = styled(Box)`
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
`

const ImageBox = styled(Box)`
    cursor: pointer;
    width: fit-content;
    height: 50px;
    margin-bottom: 10px;

    & img {
        height: 100%;
    }
`

export default BankIdPage
