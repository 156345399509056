/**
 * Calculates the time difference in seconds between the provided start time and the current time.
 *
 * @param {number | null} startTime - The start time in milliseconds since the Unix epoch or null.
 * @returns {number} The time difference in seconds. If startTime is null, returns 0.
 */

export const calculateDifferenceTime = (startTime: number | null) => {
  if (!startTime) return 0

  const currentTime = Date.now()
  const timeDifferenceInSeconds = (currentTime - startTime) / 1000
  return timeDifferenceInSeconds
}
