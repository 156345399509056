import { Box, ListItemIcon, styled, TableBody, TableCell, tableCellClasses, TableRow, Tooltip } from '@mui/material'
import moment from 'moment/moment'
import React, { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import editTableIcon from 'assets/images/icons/edit-table-icon.svg'
import removeTableIcon from 'assets/images/icons/remove-table-icon.svg'
import { DATE_FORMAT } from 'utils/getDate'
import { roundNumber } from 'utils/roundValues'

const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-last-of-type(2)': {
    borderBottom: '3px solid black'
  }
}))

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 'bold'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}))

interface IProps {
  tranchesList: any
  setTranchesList: (prev: any) => any
  summary: {
    amount: number
  }
}

const TranchesTableBody: FC<IProps> = ({ tranchesList, setTranchesList, summary }) => {
  const { t } = useTranslation()

  const handleRemove = useCallback((i) => {
    setTranchesList((prev: any) => {
      const temp = [...prev]

      if (i !== -1) {
        temp.splice(i, 1)
      }

      return temp
    })
  }, [tranchesList])

  return (
    <TableBody>
      {tranchesList?.map(({ id, amount, date, date_interest_start }: any, index: number) => {
        if (index === 0) {
          summary.amount = 0
        }
        summary.amount += +amount
        return (
          <StyledTableRow
            data-tester-tranche-row={`tranche-row-${index}`}
            key={`tranche-item-${index}`}>

            <StyledTableCell
              data-tester-tranche-cell-date={moment(date).format(DATE_FORMAT)}
            >
              {moment(date).format(DATE_FORMAT)}
            </StyledTableCell>

            <StyledTableCell
              data-tester-tranche-cell-amount={amount}
            >
              {roundNumber(amount)}
            </StyledTableCell>

            <StyledTableCell>
              <Box
                sx={{
                  display: 'flex',
                  gap: '10px'
                }}
              >
                <Tooltip title={t('common.buttons.remove')}>
                  <ListItemIcon sx={{ minWidth: '20px', cursor: 'pointer' }}>
                    <img
                      data-tester-delete-tranche-icon={index}
                      src={removeTableIcon} onClick={() => handleRemove(index)}
                      alt="Remove Icon"
                    />
                  </ListItemIcon>
                </Tooltip>
              </Box>
            </StyledTableCell>

          </StyledTableRow>
        )
      })}
      <StyledTableRow>
        <StyledTableCell>
          <span style={{ fontWeight: 'bold' }}>{t('client.applicationForm.tranches.table.head.total')}</span>
        </StyledTableCell>
        <StyledTableCell>{roundNumber(summary.amount)}</StyledTableCell>
      </StyledTableRow>
    </TableBody>
  )
}

export default TranchesTableBody
