import { clientActions } from 'redux/reducers/client/allState/clientActions'
import {
  actionTypes,
  IClearPaymentApplication,
  IClearPaymentInfo,
  ISetAppFormFetchingStatus,
  ISetAppFormToastMessage,
  ISetApplicationId,
  ISetClientActiveLoansList,
  ISetApplicationStatus,
  ISetApplicationsToBePaid,
  ISetContract,
  ISetGuarantorApplications,
  ISetGuarantorFetchingStatus,
  ISetGuarantorModels,
  ISetPaymentApplication,
  ISetPaymentGraphic,
  ISetPaymentInfo,
  ISetQuestionary,
  ISetShopRequestData,
  ISetSMSStatus,
  ISetUuidToken,
  IUpdateGuarantorModel,
  ISetActiveLoansInitFetchingStatus,
  ISetClientCurrentActiveLoan,
  ISetActiveLoansModalFetchingStatus,
  ISetClientToastMessage,
  ISetAppFormCollaterals,
  ISetAppFormCollateralsStatus,
  ISetInvoice,
  ISetAppFormTemplateType,
  IDeleteAppFormCollaterals, ISetShopRequestDataApplication, ISetClientIsLoading,
  ISetCardVerificationIsLoading,
  ISetIsCardVerified,
  ISetGuarantorStep,
  ISetGuarantorStepIndex, ISetGuarantorApplicationsList, ISetGuarantorChooseApplicationId, ISetGuarantorContract, ISetClearGuarantorApplicationsList
} from 'redux/reducers/client/allState/types/actionTypes'
import {
  CardVerificationStatus,
  clientState,
  IApplication, IApplicationDocument, IGuarantorApplication, IGuarantorContract,
  IGuarantorModel,
  IGuarantorSteps,
  IPaymentInfo,
  IShopRequestData,
  TServiceStatus
} from 'redux/reducers/client/allState/types/stateTypes'
import { IClientCollateralResponse, TCollateralStatuses } from 'redux/reducers/lender/allState/types/state'
import { IPaymentGraphic } from 'types/client/ApplicationForm'
import { IToastMessage } from 'types/common/types'

const initialState: clientState = {
  applicationForm: {
    serviceStatus: 'init',
    status: 0,
    shopRequestData: null,
    paymentGraphic: null,
    currentQuestionary: null,
    collaterals: {
      status: 'init',
      list: []
    },
    uuidToken: '',
    contract: null,
    invoice: null,
    applicationId: null,
    loanTerm: null,
    SMSStatus: null,
    templateType: '',
    toastMessage: null
  },
  activeLoans: {
    initFetchingStatus: true,
    fetchingStatus: false,
    modalFetchingStatus: false,
    list: [],
    current: null
  },
  guarantor: {
    applications: {
      list: [],
      applicationList: []
    },
    models: {
      list: []
    },
    fetchingStatus: true,
    currentStep: null,
    currentStepIndex: 0,
    chooseApplicationId: null,
    contractForm: null
  },
  applicationsToBePaid: {
    list: [],
    current: null
  },
  paymentInfo: null,
  toastMessage: null,
  isLoading: false,
  cardVerification: { isLoading: false, verifiedStatus: null }
}
const clientReducer = (state = initialState, action: actionTypes): clientState => {
  switch (action.type) {
    case clientActions.SET_UUID_TOKEN: {
      return { ...state, applicationForm: { ...state.applicationForm, uuidToken: action.payload } }
    }
    case clientActions.SET_PAYMENT_GRAPHIC: {
      return {
        ...state,
        applicationForm: { ...state.applicationForm, paymentGraphic: action.payload }
      }
    }
    case clientActions.SET_QUESTIONARY: {
      return {
        ...state,
        applicationForm: { ...state.applicationForm, currentQuestionary: action.payload }
      }
    }
    case clientActions.SET_SHOP_REQUEST_DATA: {
      return {
        ...state,
        applicationForm: { ...state.applicationForm, shopRequestData: action.payload }
      }
    }
    case clientActions.SET_SHOP_REQUEST_DATA_APPLICATION: {
      if (state.applicationForm.shopRequestData) {
        return {
          ...state,
          applicationForm: {
            ...state.applicationForm,
            shopRequestData: {
              ...state.applicationForm.shopRequestData,
              application: action.payload
            }
          }
        }
      }
      return state
    }
    case clientActions.SET_CONTRACT: {
      return {
        ...state,
        applicationForm: { ...state.applicationForm, contract: action.payload }
      }
    }
    case clientActions.SET_INVOICE: {
      return {
        ...state,
        applicationForm: { ...state.applicationForm, invoice: action.payload }
      }
    }
    case clientActions.SET_APP_FORM_TEMPLATE_TYPE: {
      return {
        ...state,
        applicationForm: { ...state.applicationForm, templateType: action.payload }
      }
    }
    // <> active loans
    case clientActions.SET_ACTIVE_LOANS_INIT_FETCHING_STATUS: {
      return {
        ...state,
        activeLoans: {
          ...state.activeLoans,
          initFetchingStatus: action.payload
        }
      }
    }
    case clientActions.SET_CLIENT_ACTIVE_LOANS_LIST: {
      return {
        ...state,
        activeLoans: {
          ...state.activeLoans,
          list: action.payload
        }
      }
    }
    case clientActions.SET_CLIENT_CURRENT_ACTIVE_LOAN: {
      return {
        ...state,
        activeLoans: {
          ...state.activeLoans,
          current: action.payload
        }
      }
    }
    case clientActions.SET_ACTIVE_LOANS_MODAL_FETCHING_STATUS: {
      return {
        ...state,
        activeLoans: {
          ...state.activeLoans,
          modalFetchingStatus: action.payload
        }
      }
    }
    // </> active loans
    case clientActions.SET_APPLICATIONS_TO_BE_PAID: {
      const applications = action.payload.filter(({ status }: { status: { id: number } }) => [5, 6, 8, 14].includes(status.id))

      return {
        ...state,
        applicationsToBePaid: { ...state.applicationsToBePaid, list: applications }
      }
    }
    case clientActions.SET_APPLICATION_ID: {
      localStorage.setItem('applicationId', action.payload.toString())
      return {
        ...state,
        applicationForm: { ...state.applicationForm, applicationId: action.payload }
      }
    }
    case clientActions.SET_APPLICATION_STATUS: {
      localStorage.setItem('applicationStatus', action.payload.toString())
      return {
        ...state,
        applicationForm: {
          ...state.applicationForm,
          status: action.payload
        }
      }
    }
    case clientActions.SET_APP_FORM_FETCHING_STATUS: {
      return {
        ...state,
        applicationForm: {
          ...state.applicationForm,
          serviceStatus: action.payload
        }
      }
    }
    case clientActions.SET_APP_FORM_COLLATERALS: {
      return {
        ...state,
        applicationForm: {
          ...state.applicationForm,
          collaterals: {
            ...state.applicationForm.collaterals,
            list: action.payload
          }
        }
      }
    }
    case clientActions.DELETE_APP_FORM_COLLATERALS: {
      const { applicationForm } = state
      const { collaterals } = applicationForm
      const updatedCollaterals = collaterals.list.filter(collateral => collateral.id !== action.payload)
      return {
        ...state,
        applicationForm: {
          ...applicationForm,
          collaterals: {
            ...collaterals,
            list: updatedCollaterals
          }
        }
      }
    }
    case clientActions.SET_APP_FORM_COLLATERALS_STATUS: {
      return {
        ...state,
        applicationForm: {
          ...state.applicationForm,
          collaterals: {
            ...state.applicationForm.collaterals,
            status: action.payload
          }
        }
      }
    }
    case clientActions.SET_APP_FORM_TOAST_MESSAGE: {
      return {
        ...state,
        applicationForm: {
          ...state.applicationForm,
          toastMessage: action.payload
        }
      }
    }

    case clientActions.SET_SMS_STATUS: {
      return {
        ...state,
        applicationForm: {
          ...state.applicationForm,
          SMSStatus: action.payload
        }
      }
    }

    case clientActions.SET_PAYMENT_APPLICATION: {
      return {
        ...state,
        applicationsToBePaid: { ...state.applicationsToBePaid, current: action.payload }
      }
    }
    case clientActions.SET_PAYMENT_INFO: {
      return { ...state, paymentInfo: action.payload }
    }

    case clientActions.CLEAR_PAYMENT_APPLICATION: {
      return { ...state, applicationsToBePaid: { ...state.applicationsToBePaid, current: null } }
    }
    // eslint-disable-next-line no-duplicate-case
    case clientActions.CLEAR_PAYMENT_APPLICATION: {
      return { ...state, paymentInfo: null }
    }
    // guarantor
    case clientActions.SET_GUARANTOR_APPLICATIONS: {
      return {
        ...state,
        guarantor: {
          ...state.guarantor,
          applications: {
            ...state.guarantor.applications,
            list: action.payload
          }
        }
      }
    }
    case clientActions.SET_GUARANTOR_APPLICATIONS_LIST: {
      const applicationList = [...state.guarantor.applications.applicationList]

      if (action.payload) {
        const findIndex = applicationList.findIndex((application) => application.id === action.payload?.id)

        if (findIndex !== -1) {
          applicationList[findIndex] = action.payload
        } else {
          applicationList.push(action.payload)
        }
      }

      return {
        ...state,
        guarantor: {
          ...state.guarantor,
          applications: {
            ...state.guarantor.applications,
            applicationList: action.payload ? applicationList : []
          }
        }
      }
    }
    case clientActions.CLEAR_GUARANTOR_APPLICATIONS_LIST: {
      return {
        ...state,
        guarantor: {
          ...state.guarantor,
          applications: {
            ...state.guarantor.applications,
            applicationList: []
          }
        }
      }
    }
    case clientActions.SET_GUARANTOR_MODELS: {
      return {
        ...state,
        guarantor: {
          ...state.guarantor,
          models: {
            ...state.guarantor.models,
            list: action.payload
          }
        }
      }
    }
    case clientActions.UPDATE_GUARANTOR_MODEL: {
      const updatedList = state.guarantor.models.list.map(model =>
        model.uuid === action.payload.uuid ? action.payload : model
      )

      return {
        ...state,
        guarantor: {
          ...state.guarantor,
          models: {
            ...state.guarantor.models,
            list: updatedList
          }
        }
      }
    }
    case clientActions.SET_GUARANTOR_FETCHING_STATUS: {
      return {
        ...state,
        guarantor: {
          ...state.guarantor,
          fetchingStatus: action.payload
        }
      }
    }
    case clientActions.SET_GUARANTOR_STEP_INDEX: {
      return {
        ...state,
        guarantor: {
          ...state.guarantor,
          currentStepIndex: action.payload
        }
      }
    }
    case clientActions.SET_GUARANTOR_CHOOSE_APPLICATION_ID: {
      return {
        ...state,
        guarantor: {
          ...state.guarantor,
          chooseApplicationId: action.payload
        }
      }
    }
    case clientActions.SET_GUARANTOR_CONTRACT: {
      return {
        ...state,
        guarantor: {
          ...state.guarantor,
          contractForm: action.payload
        }
      }
    }
    case clientActions.SET_CLIENT_TOAST_MESSAGE: {
      return {
        ...state,
        toastMessage: action.payload
      }
    }

    case clientActions.SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.payload
      }
    }
    case clientActions.SET_IS_VERIFICATION_LOADING: {
      return {
        ...state,
        cardVerification: {
          ...state.cardVerification,
          isLoading: action.payload
        }
      }
    }
    case clientActions.SET_VERIFIED_STATUS: {
      return {
        ...state,
        cardVerification: {
          ...state.cardVerification,
          verifiedStatus: action.payload
        }
      }
    }
    default:
      return state
  }
}
// actions
export const setUuidToken = (token: string): ISetUuidToken => ({
  type: clientActions.SET_UUID_TOKEN,
  payload: token
})
export const setPaymentGraphic = (data: IPaymentGraphic[]): ISetPaymentGraphic => ({
  type: clientActions.SET_PAYMENT_GRAPHIC,
  payload: data
})
export const setCurrentQuestionary = (data: any): ISetQuestionary => ({
  type: clientActions.SET_QUESTIONARY,
  payload: data
})
export const setShopRequestData = (data: IShopRequestData): ISetShopRequestData => ({
  type: clientActions.SET_SHOP_REQUEST_DATA,
  payload: data
})
export const setShopRequestDataApplication = (data: IApplication): ISetShopRequestDataApplication => ({
  type: clientActions.SET_SHOP_REQUEST_DATA_APPLICATION,
  payload: data
})
export const setContract = (data: IApplicationDocument): ISetContract => ({
  type: clientActions.SET_CONTRACT,
  payload: data
})
export const setInvoice = (data: IApplicationDocument): ISetInvoice => ({
  type: clientActions.SET_INVOICE,
  payload: data
})
export const setAppFormTemplateType = (data: string): ISetAppFormTemplateType => ({
  type: clientActions.SET_APP_FORM_TEMPLATE_TYPE,
  payload: data
})

// <> active loans
export const setActiveLoansInitFetchungStatus = (
  status: boolean
): ISetActiveLoansInitFetchingStatus => ({
  type: clientActions.SET_ACTIVE_LOANS_INIT_FETCHING_STATUS,
  payload: status
})
export const setClientActiveLoansList = (data: IApplication[]): ISetClientActiveLoansList => ({
  type: clientActions.SET_CLIENT_ACTIVE_LOANS_LIST,
  payload: data
})
export const setClientCurrentActiveLoan = (
  data: IApplication | null
): ISetClientCurrentActiveLoan => ({
  type: clientActions.SET_CLIENT_CURRENT_ACTIVE_LOAN,
  payload: data
})
export const setActiveLoansModalFetchingStatus = (
  status: boolean
): ISetActiveLoansModalFetchingStatus => ({
  type: clientActions.SET_ACTIVE_LOANS_MODAL_FETCHING_STATUS,
  payload: status
})
// </> active loans

export const setApplicationId = (id: number): ISetApplicationId => ({
  type: clientActions.SET_APPLICATION_ID,
  payload: id
})
export const SetApplicationStatus = (status: number): ISetApplicationStatus => ({
  type: clientActions.SET_APPLICATION_STATUS,
  payload: status
})
export const setAppFormFetchingStatus = (status: TServiceStatus): ISetAppFormFetchingStatus => ({
  type: clientActions.SET_APP_FORM_FETCHING_STATUS,
  payload: status
})
export const setAppFormCollaterals = (
  collaterals: IClientCollateralResponse[]
): ISetAppFormCollaterals => ({
  type: clientActions.SET_APP_FORM_COLLATERALS,
  payload: collaterals
})
export const deleteAppFormCollaterals = (
  collateralsId: number
): IDeleteAppFormCollaterals => ({
  type: clientActions.DELETE_APP_FORM_COLLATERALS,
  payload: collateralsId
})
export const setAppFormCollateralsStatus = (
  status: TCollateralStatuses
): ISetAppFormCollateralsStatus => ({
  type: clientActions.SET_APP_FORM_COLLATERALS_STATUS,
  payload: status
})
export const setAppFormToastMessage = (message: IToastMessage | null): ISetAppFormToastMessage => ({
  type: clientActions.SET_APP_FORM_TOAST_MESSAGE,
  payload: message
})
export const SetSMSStatus = (status: string): ISetSMSStatus => ({
  type: clientActions.SET_SMS_STATUS,
  payload: status
})
export const setApplicationsToBePaid = (data: IApplication[]): ISetApplicationsToBePaid => ({
  type: clientActions.SET_APPLICATIONS_TO_BE_PAID,
  payload: data
})

export const setPaymentApplication = (data: IApplication): ISetPaymentApplication => ({
  type: clientActions.SET_PAYMENT_APPLICATION,
  payload: data
})
export const setPaymentInfo = (data: IPaymentInfo): ISetPaymentInfo => ({
  type: clientActions.SET_PAYMENT_INFO,
  payload: data
})

export const clearPaymentApplication = (): IClearPaymentApplication => ({
  type: clientActions.CLEAR_PAYMENT_APPLICATION
})
export const clearPaymentInfo = (): IClearPaymentInfo => ({
  type: clientActions.CLEAR_PAYMENT_INFO
})

// guarantor
export const setGuarantorApplications = (
  applications: IGuarantorApplication[]
): ISetGuarantorApplications => ({
  type: clientActions.SET_GUARANTOR_APPLICATIONS,
  payload: applications
})

export const setGuarantorApplicationsList = (
  applications: IApplication | null
): ISetGuarantorApplicationsList => ({
  type: clientActions.SET_GUARANTOR_APPLICATIONS_LIST,
  payload: applications
})

export const setClearGuarantorApplicationsList = (): ISetClearGuarantorApplicationsList => ({
  type: clientActions.CLEAR_GUARANTOR_APPLICATIONS_LIST,
  payload: []
})

export const setGuarantorModels = (applications: IGuarantorModel[]): ISetGuarantorModels => ({
  type: clientActions.SET_GUARANTOR_MODELS,
  payload: applications
})

export const updateGuarantorModel = (model: IGuarantorModel): IUpdateGuarantorModel => ({
  type: clientActions.UPDATE_GUARANTOR_MODEL,
  payload: model
})

export const setGuarantorFetchingStatus = (status: boolean): ISetGuarantorFetchingStatus => ({
  type: clientActions.SET_GUARANTOR_FETCHING_STATUS,
  payload: status
})
export const setGuarantorStep = (step: IGuarantorSteps): ISetGuarantorStep => ({
  type: clientActions.SET_GUARANTOR_STEP,
  payload: step
})
export const setGuarantorStepIndex = (index: number): ISetGuarantorStepIndex => ({
  type: clientActions.SET_GUARANTOR_STEP_INDEX,
  payload: index
})

export const setGuarantorChooseApplicationId = (applicationId: number): ISetGuarantorChooseApplicationId => ({
  type: clientActions.SET_GUARANTOR_CHOOSE_APPLICATION_ID,
  payload: applicationId
})

export const setClientToastMessage = (
  toastMessage: IToastMessage | null
): ISetClientToastMessage => ({
  type: clientActions.SET_CLIENT_TOAST_MESSAGE,
  payload: toastMessage
})

export const setClientIsLoading = (
  isLoading: boolean
): ISetClientIsLoading => ({
  type: clientActions.SET_IS_LOADING,
  payload: isLoading
})
export const setCardVerificationIsLoading = (
  isLoading: boolean
): ISetCardVerificationIsLoading => ({
  type: clientActions.SET_IS_VERIFICATION_LOADING,
  payload: isLoading
})
export const setCardVerifiedStatus = (
  status: CardVerificationStatus
): ISetIsCardVerified => ({
  type: clientActions.SET_VERIFIED_STATUS,
  payload: status
})
export const setGuarantorContract = (
  data: IGuarantorContract | null
): ISetGuarantorContract => ({
  type: clientActions.SET_GUARANTOR_CONTRACT,
  payload: data
})

export default clientReducer
