import { Paper } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router'

import { useSearchParams } from 'components/common/hooks/useSearchParams'
import LoginForm from 'components/pageHome/loginPage/LoginForm/loginForm'
import { wavesSVG } from 'components/pageHome/ui/wavesSVG'
import { loginThunk } from 'redux/reducers/auth/authThunks'
import { loginData, userData } from 'redux/reducers/auth/types/stateTypes'
import { translateType } from 'translations/types'

import cls from '../AuthPages.module.scss'

import RecoverPassword from './recoverPassword'

interface IProps {
  loginThunk: (data: loginData) => any
  t: translateType
  currentUser: userData | null
}

const LoginPage: React.FC<IProps> = ({ loginThunk, t, currentUser }) => {
  const searchParams = useSearchParams()
  const [isRecover, setIsRecover] = useState(false)
  const history = useHistory()

  const guarantorUuidToken = searchParams('guarantor_uuid')

  useEffect(() => {
    if (guarantorUuidToken) {
      localStorage.setItem('guarantor_uuid_token', guarantorUuidToken)
    }
  }, [guarantorUuidToken])

  useEffect(() => {
    switch (currentUser?.group) {
      case 'Client': {
        history.push('/client/active-loans')
        break
      }
      case 'Admin':
      case 'Lead':
      case 'Lead:Middle':
      case 'Lead:Soft':
      case 'Lead:Verifier':
      case 'Lender':
      case 'Manager':
      case 'Verifier': {
        history.push('/lender/dashboard')
        break
      }
      case 'Commerce': {
        history.push('/commerce')
        break
      }
    }
  }, [currentUser])

  const recoverHandler = () => setIsRecover(!isRecover)

  const onSubmit = async (data: loginData) => {
    await loginThunk(data)
  }
  return (
    <Paper className={cls.paper}>
      { isRecover
        ? <RecoverPassword recoverHandler={recoverHandler} t={t}/>
        : <LoginForm onSubmit={onSubmit} t={t} recoverHandler={recoverHandler}/>
      }
      { wavesSVG(cls.waves) }
    </Paper>
  )
}

export default connect(null, { loginThunk })(LoginPage)
