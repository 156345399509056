import { getWindowDimensions } from './helpers'

export const getModalStyle = () => {
  const { width: clientWidth } = getWindowDimensions()
  return {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: '100%',
    width: clientWidth > 800 ? '55%' : '75%',
    overflow: 'auto'
  }
}
