import axios, { AxiosInstance } from 'axios'

import { API_URL, API_V2_URL, DAILY_CO_BASE_API_URL, DAILY_CO_TOKEN } from 'config'
import { getCookie, getLanguage } from 'utils/common/apiHelpers'

function createAxiosInstance (apiUrl: string): AxiosInstance {
  const instance = axios.create()
  instance.interceptors.request.use(
    function (config) {
      config.baseURL = apiUrl

      return config
    },
    function (error) {
      return Promise.reject(error)
    }
  )

  return instance
}

function createPrivateAxiosInstance (apiUrl: string): AxiosInstance {
  const instance = axios.create()
  instance.interceptors.request.use(
    function (config) {
      if (config.headers !== undefined) {
        const language = getLanguage()
        const token = getCookie('token')

        if (language) {
          config.headers['Accept-Language'] = language
        }
        if (token) {
          config.headers.Authorization = `Token ${token}`
        }
      }
      config.baseURL = apiUrl

      return config
    },
    function (error) {
      return Promise.reject(error)
    }
  )

  return instance
}

/* Daily.co */
function createPrivateAxiosInstanceDailyCo (apiUrl: string): AxiosInstance {
  const instance = axios.create()
  instance.interceptors.request.use(
    function (config) {
      if (config.headers !== undefined) {
        const language = getLanguage()

        if (language) {
          config.headers['Accept-Language'] = language
        }

        config.headers.Authorization = `Bearer ${DAILY_CO_TOKEN}`
      }
      config.baseURL = apiUrl

      return config
    },
    function (error) {
      return Promise.reject(error)
    }
  )

  return instance
}

export const axiosInstance = createAxiosInstance(API_URL)
export const axiosV2Instance = createAxiosInstance(API_V2_URL)
export const axiosInstancePrivate = createPrivateAxiosInstance(API_URL)
export const axiosV2InstancePrivate = createPrivateAxiosInstance(API_V2_URL)

export const axiosInstancePrivateDailyCo = createPrivateAxiosInstanceDailyCo(DAILY_CO_BASE_API_URL)
