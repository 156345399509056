import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Typography
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import PaperContainer from 'components/UI/PaperContainer'
import { userFlowSelector } from 'redux/reducers/auth/authSelectors'
import {
  appFormContractSelector,
  appFormInvoiceSelector,
  appFormShopRequestDataSelector,
  appFormTemplateTypeSelector
} from 'redux/reducers/client/allState/clientSelectors'
import {
  getClientContractByAppIdThunk,
  getSMSCodeThunk
} from 'redux/reducers/client/allState/clientThunks'
import { constructorActions } from 'redux/reducers/constructors/formConstructor/state/actions'
import { calculateDifferenceTime } from 'utils/client/calculateDifferenceTime'

import VerificationCodeForm from './verificationCodeForm'

const VerificationPage: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { appFormFlowData } = useSelector(userFlowSelector)
  const shopRequestData = useSelector(appFormShopRequestDataSelector)
  const templateType = useSelector(appFormTemplateTypeSelector)

  const contract = useSelector(appFormContractSelector)
  const invoice = useSelector(appFormInvoiceSelector)

  const [disabled, setDisabled] = useState(false)
  const [loadContract, setLoadContract] = useState(false)
  const [loadInvoice, setLoadInvoice] = useState(false)
  const [startTime, setStartTime] = useState<number | null>(null)

  useEffect(() => {
    const getClientContract = async () => {
      if (appFormFlowData) {
        const {
          flow_data: { id }
        } = appFormFlowData

        if (!contract) {
          setLoadContract(true)
          await dispatch(getClientContractByAppIdThunk(id, 'Contract'))
          setLoadContract(false)
        }
        if (
          shopRequestData?.credit_policy.credit_type === 'Consumer finance' &&
          templateType === 'entity'
        ) {
          setLoadInvoice(true)
          await dispatch(getClientContractByAppIdThunk(id, 'Invoice'))
          setLoadInvoice(false)
        }
      }
    }

    getClientContract()
  }, [
    appFormFlowData,
    contract,
    dispatch,
    shopRequestData?.credit_policy.credit_type,
    templateType
  ])

  useEffect(() => {
    dispatch(constructorActions.clientApplication.getClientApplication())
  }, [dispatch])

  const handleCodeRequest = () => {
    if (appFormFlowData) {
      const contractTime = calculateDifferenceTime(startTime)

      setDisabled(true)
      dispatch(getSMSCodeThunk(appFormFlowData.flow_data.id, contractTime))
      setTimeout(() => {
        setDisabled(false)
      }, 60000)
    }
  }

  const handleAccordionOpen = () => setStartTime(Date.now())
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => (event.key === 'Enter' || event.key === ' ') ? handleAccordionOpen() : null

  if (!appFormFlowData) return <Typography>No appFormFlowData</Typography>

  const { id } = appFormFlowData

  return (
    <>
      <PaperContainer style={{ marginBottom: '30px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography variant="h4" mb={4}>
            {t('lender.creditQuestionaryForm.questionary.familiarizeWithContract')}
          </Typography>
          {(loadContract || loadInvoice) && <CircularProgress />}
          {contract && (
            <Accordion
              onClick={handleAccordionOpen}
              onKeyDown={handleKeyDown}
              square
              sx={{ width: '100%', boxShadow: 'none !important', border: 'none !important' }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{ '& .MuiAccordionSummary-content': { justifyContent: 'center' } }}
              >
                <Typography>{t('client.applicationForm.verification.showContract')}</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ p: '0px !important' }}>
                <Box
                  id-testing='verification_page_contract_client_step_4'
                  sx={{
                    width: '100%',
                    '& section': {
                      p: '0px !important',
                      width: '100% !important',
                      boxShadow: 'none !important',
                      overflow: 'auto !important'
                    }
                  }}
                  dangerouslySetInnerHTML={{ __html: contract?.document }}
                />
              </AccordionDetails>
            </Accordion>
          )}
          {invoice && (
            <Accordion
              sx={{ width: '100%', boxShadow: 'none !important', border: 'none !important' }}
              square
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{ '& .MuiAccordionSummary-content': { justifyContent: 'center' } }}
              >
                <Typography>{t('client.applicationForm.verification.showInvoice')}</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ p: '0px !important' }}>
                <Box
                  id-testing='verification_page_invoice_client_step_4'
                  sx={{
                    width: '100%',
                    '& section': {
                      p: '0px !important',
                      width: '100% !important',
                      boxShadow: 'none !important',
                      overflow: 'auto !important'
                    }
                  }}
                  dangerouslySetInnerHTML={{ __html: invoice?.document }}
                />
              </AccordionDetails>
            </Accordion>
          )}
        </Box>
      </PaperContainer>
      <PaperContainer>
        <Box mb={8}>
          <Typography variant="h4" mb={4} align="center">
            {t('lender.creditQuestionaryForm.questionary.confirmEnter')}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button variant="contained" onClick={handleCodeRequest} disabled={disabled}>
              {t('lender.creditQuestionaryForm.questionary.getVerifiedCode')}
            </Button>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <VerificationCodeForm applicationId={appFormFlowData.flow_data.id} flowId={id} />
        </Box>
      </PaperContainer>
    </>
  )
}

export default VerificationPage
