import { saveAs } from 'file-saver'

import { translateType } from 'translations/types'

interface ICharMap {
  [key: string]: string
}

const charMap: ICharMap = {
  // Russian
  А: 'A',
  Б: 'B',
  В: 'V',
  Г: 'G',
  Д: 'D',
  Е: 'E',
  Ё: 'Yo',
  Ж: 'Zh',
  З: 'Z',
  И: 'I',
  Й: 'J',
  К: 'K',
  Л: 'L',
  М: 'M',
  Н: 'N',
  О: 'O',
  П: 'P',
  Р: 'R',
  С: 'S',
  Т: 'T',
  У: 'U',
  Ф: 'F',
  Х: 'H',
  Ц: 'C',
  Ч: 'Ch',
  Ш: 'Sh',
  Щ: 'Sh',
  Ъ: '_',
  Ы: 'Y',
  Ь: '_',
  Э: 'E',
  Ю: 'Yu',
  Я: 'Ya',
  а: 'a',
  б: 'b',
  в: 'v',
  г: 'g',
  д: 'd',
  е: 'e',
  ё: 'yo',
  ж: 'zh',
  з: 'z',
  и: 'i',
  й: 'y',
  к: 'k',
  л: 'l',
  м: 'm',
  н: 'n',
  о: 'o',
  п: 'p',
  р: 'r',
  с: 's',
  т: 't',
  у: 'u',
  ф: 'f',
  х: 'h',
  ц: 'ts',
  ч: 'ch',
  ш: 'sh',
  щ: 'sh',
  ъ: '_',
  ы: 'y',
  ь: '_',
  э: 'e',
  ю: 'yu',
  я: 'ya',

  // Ukrainian
  Є: 'Ye',
  І: 'I',
  Ї: 'Yi',
  Ґ: 'G',
  є: 'ye',
  і: 'i',
  ї: 'yi',
  ґ: 'g'
}

// replace cyrillic
export const replaceCyrillic = (value: string): string => {
  if (value?.length !== 0) {
    const valueLowerCase = value.toLowerCase()
    const splitValue: string[] = valueLowerCase.split('').map(el => charMap[el] || el)

    return splitValue.join('')
  }
  return value
}

// get MB to Bytes
export const getMbToBytes = (mb: number): number => mb * Math.pow(1024, 2)

// responsive
export const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window
  return { width, height }
}

const isToday = (date: Date | string | undefined): boolean => {
  if (date) {
    const today = new Date()
    date = new Date(date)

    return today.toDateString() === date.toDateString()
  }

  return false
}

export { isToday }
export const breakpointWidth = 992

export const downloadExportImport = (blobObj: BlobPart, fileName: string, fileExtension: string) => {
  const blob = new Blob([blobObj])
  saveAs(blob, `${fileName}${fileExtension}`)
}

// get months or days
export const monthDiff = (d1: string, d2: string, t: translateType) => {
  let months
  const parseDate1 = new Date(d1)
  const parseDate2 = new Date(d2)
  months = (parseDate2.getFullYear() - parseDate1.getFullYear()) * 12
  months -= parseDate1.getMonth() + 1
  months += parseDate2.getMonth()
  const days = Math.ceil(Math.abs(parseDate2.getTime() - parseDate1.getTime()) / (1000 * 3600 * 24))

  if (months <= 0) {
    return days <= 0 ? 0 : `${days} ${t('common.days.days')}`
  }

  return months <= 0 ? 0 : `${months} ${t('common.days.month')}`
}
