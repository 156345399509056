import { Box, Modal } from '@mui/material'
import React, { ReactNode } from 'react'

import { getModalStyle } from 'utils/getModalStyle'

interface IProps {
  isVisible: boolean;
  closeModal: () => void;
  children: ReactNode;
}
const MyModal = ({ isVisible, closeModal, children }: IProps) => {
  return (
    <Modal
      open={isVisible}
      onClose={closeModal}
    >
      <Box sx={getModalStyle()}>
        {children}
      </Box>
    </Modal>
  )
}

export default MyModal
