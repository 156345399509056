import { Box, Button, TextField, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { clientApi } from 'api/clientApi'
import { deleteAppFormUserFlowThunk } from 'redux/reducers/auth/authThunks'
import {
  appFormCreditPolicyData,
  appFormSmsStatusSelector
} from 'redux/reducers/client/allState/clientSelectors'
import { getActiveLoansThunk, sendSMSCodeThunk } from 'redux/reducers/client/allState/clientThunks'

interface IProps {
  applicationId: number
  flowId: number
}

const VerificationCodeForm: React.FC<IProps> = ({ applicationId, flowId }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const { register, handleSubmit } = useForm({ mode: 'onChange' })

  const SMSStatus = useSelector(appFormSmsStatusSelector)
  const currentPolicy = useSelector(appFormCreditPolicyData)

  useEffect(() => {
    if (!currentPolicy) return

    if (SMSStatus === 'Successful') {
      // 5 - Кредит: Прийнятий
      // 11 - Очікування гаранта
      // const { required_guarantors_min } = currentPolicy
      // const statusToUpdate = required_guarantors_min > 0 ? 11 : 5
      const statusToUpdate = 5

      clientApi.applications
        .patchApplication(applicationId, {
          status: statusToUpdate
        })
        .then(res => {
          if (res.status === 200) {
            // @ts-ignore
            dispatch(deleteAppFormUserFlowThunk(flowId)).then(isSuccess => {
              if (isSuccess) history.replace('/client/active-loans')
            })
            dispatch(getActiveLoansThunk())
          }
        })
    }
  }, [SMSStatus, applicationId, dispatch, flowId, history])

  const onSubmit = (data: any) => {
    if (applicationId) {
      dispatch(sendSMSCodeThunk(applicationId, data.sms_code))
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box mb={4}>
        <TextField
          variant="standard"
          label={t('lender.creditQuestionaryForm.questionary.sms')}
          {...register('sms_code')}
          type="text"
        />
        {SMSStatus === 'Invalid application status!' && (
          <Typography color="error">
            {t('lender.creditQuestionaryForm.questionary.invalidSMSCode')}
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <Button type="submit" variant="contained">
          {t('common.buttons.save')}
        </Button>
      </Box>
    </form>
  )
}

export default VerificationCodeForm
