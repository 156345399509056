export const clientActions = {
  CLEAR_PAYMENT_APPLICATION: 'CLEAR_PAYMENT_APPLICATION',
  CLEAR_PAYMENT_INFO: 'CLEAR_PAYMENT_INFO',

  SET_ACTIVE_LOANS_INIT_FETCHING_STATUS: 'SET_ACTIVE_LOANS_INIT_FETCHING_STATUS',
  SET_ACTIVE_LOANS_MODAL_FETCHING_STATUS: 'SET_ACTIVE_LOANS_MODAL_FETCHING_STATUS',
  SET_CLIENT_ACTIVE_LOANS_LIST: 'SET_CLIENT_ACTIVE_LOANS_LIST',
  SET_CLIENT_CURRENT_ACTIVE_LOAN: 'SET_CLIENT_CURRENT_ACTIVE_LOAN',

  SET_APPLICATIONS_TO_BE_PAID: 'SET_APPLICATIONS_TO_BE_PAID',
  SET_APPLICATION_STATUS: 'SET_APPLICATION_STATUS',
  SET_APP_FORM_FETCHING_STATUS: 'SET_APP_FORM_FETCHING_STATUS',
  SET_APP_FORM_COLLATERALS: 'SET_APP_FORM_COLLATERALS',
  SET_APP_FORM_COLLATERALS_STATUS: 'SET_APP_FORM_COLLATERALS_STATUS',
  SET_APP_FORM_TOAST_MESSAGE: 'SET_APP_FORM_TOAST_MESSAGE',
  SET_APPLICATION_ID: 'SET_APPLICATION_ID',
  SET_SMS_STATUS: 'SET_SMS_STATUS',

  SET_CONTRACT: 'SET_CONTRACT',
  SET_INVOICE: 'SET_INVOICE',
  SET_APP_FORM_TEMPLATE_TYPE: 'SET_APP_FORM_TEMPLATE_TYPE',
  SET_PAYMENT_APPLICATION: 'SET_PAYMENT_APPLICATION',
  SET_PAYMENT_INFO: 'SET_PAYMENT_INFO',
  SET_PAYMENT_GRAPHIC: 'SET_PAYMENT_GRAPHIC',
  SET_QUESTIONARY: 'SET_QUESTIONARY',
  SET_SHOP_REQUEST_DATA: 'SET_SHOP_REQUEST_DATA',
  SET_SHOP_REQUEST_DATA_APPLICATION: 'SET_SHOP_REQUEST_DATA_APPLICATION',
  SET_UUID_TOKEN: 'SET_UUID_TOKEN',

  SET_GUARANTOR_APPLICATIONS: 'SET_GUARANTOR_APPLICATIONS',
  SET_GUARANTOR_APPLICATIONS_LIST: 'SET_GUARANTOR_APPLICATIONS_LIST',
  CLEAR_GUARANTOR_APPLICATIONS_LIST: 'CLEAR_GUARANTOR_APPLICATIONS_LIST',
  SET_GUARANTOR_MODELS: 'SET_GUARANTOR_MODELS',
  UPDATE_GUARANTOR_MODEL: 'UPDATE_GUARANTOR_MODEL',
  SET_GUARANTOR_FETCHING_STATUS: 'SET_GUARANTOR_FETCHING_STATUS',
  SET_GUARANTOR_STEP: 'SET_GUARANTOR_STEP',
  SET_GUARANTOR_STEP_INDEX: 'SET_GUARANTOR_STEP_INDEX',
  SET_GUARANTOR_CHOOSE_APPLICATION_ID: 'SET_GUARANTOR_CHOOSE_APPLICATION_ID',
  SET_GUARANTOR_CONTRACT: 'SET_GUARANTOR_CONTRACT',

  SET_CLIENT_TOAST_MESSAGE: 'SET_CLIENT_TOAST_MESSAGE',

  DELETE_APP_FORM_COLLATERALS: 'DELETE_APP_FORM_COLLATERALS',

  SET_IS_LOADING: 'SET_IS_LOADING',
  SET_IS_VERIFICATION_LOADING: 'SET_IS_VERIFICATION_LOADING',
  SET_VERIFIED_STATUS: 'SET_VERIFIED_STATUS'
} as const
