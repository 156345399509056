import { createAction, createAsyncThunk } from '@reduxjs/toolkit'

import { clientApi } from 'api/clientApi'
import { mockResponse } from 'components/pageClient/guaranteePage/application/mockGuarantorFormResponse'
import { ICustomInput } from 'features/formConstructor/types'
import { updateAppFormUserFlowThunk } from 'redux/reducers/auth/authThunks'
import { setAppFormTemplateType, setClientToastMessage } from 'redux/reducers/client/allState/clientReducer'
import { formConstructorApi } from 'redux/reducers/constructors/formConstructor/api'
import { setLenderToastMessage } from 'redux/reducers/lender/allState/lenderReducer'
import { ICreditProduct } from 'redux/reducers/lender/allState/types/state'
import { getErrorMessage } from 'utils/getErrorMessage'

import {
  ICurrentTemplatesStep,
  IMandatoryQuestions,
  ITemplate,
  ITemplateBlock,
  ITemplateList,
  ITemplateListItem,
  ITemplateRequest,
  ITemplateStep,
  IClientTemplateUpdateData
} from '../types'

export const constructorActions = {
  creators: {
    template: {
      setIsNewOpen: createAction<boolean>('FORM_CONSTRUCTOR/TEMPLATE_CREATOR/SET_NEW_OPEN'),
      setIsEditOpen: createAction<boolean>('FORM_CONSTRUCTOR/TEMPLATE_CREATOR/SET_EDIT_OPEN')
    },
    step: {
      setIsNewOpen: createAction<boolean>('FORM_CONSTRUCTOR/STEP_CREATOR/SET_NEW_OPEN'),
      setIsEditOpen: createAction<boolean>('FORM_CONSTRUCTOR/STEP_CREATOR/SET_EDIT_OPEN')
    },
    block: {
      setIsNewOpen: createAction<boolean>('FORM_CONSTRUCTOR/BLOCK_CREATOR/SET_NEW_OPEN'),
      setIsEditOpen: createAction<boolean>('FORM_CONSTRUCTOR/BLOCK_CREATOR/SET_EDIT_OPEN')
    },
    input: {
      setIsNewOpen: createAction<boolean>('FORM_CONSTRUCTOR/INPUT_CREATOR/SET_NEW_OPEN'),
      setIsEditOpen: createAction<boolean>('FORM_CONSTRUCTOR/INPUT_CREATOR/SET_EDIT_OPEN')
    }
  },
  menu: {
    isOpen: createAction<boolean>('FORM_CONSTRUCTOR/SET_IS_OPEN'),
    setCurrentTemplateId: createAction<string>('FORM_CONSTRUCTOR/MENU/CURRENT_TEMPLATE_ID/SET'),
    setCurrentStep: createAction<string>('FORM_CONSTRUCTOR/MENU/CURRENT_STEP/SET'),
    setCurrentBlockId: createAction<string>('FORM_CONSTRUCTOR/MENU/CURRENT_BLOCK_ID/SET'),
    setCurrentMandatoryQuestionId: createAction<string>(
      'FORM_CONSTRUCTOR/MENU/CURRENT_MANDATORY_QUESTION/SET'
    ),
    setCurrentInputId: createAction<string>('FORM_CONSTRUCTOR/MENU/CURRENT_INPUT_ID/SET')
  },
  templatesList: {
    set: createAction<ITemplateList>('FORM_CONSTRUCTOR/TEMPLATES/LIST/SET'),
    addTemplate: createAction<ITemplateListItem>('FORM_CONSTRUCTOR/TEMPLATES/ADD_TEMPLATE'),

    get: createAsyncThunk('FORM_CONSTRUCTOR/TEMPLATE/GET_LIST', async (_, thunkAPI) => {
      try {
        const res = await formConstructorApi.getApplicationTemplates()

        thunkAPI.dispatch(
          constructorActions.templatesList.set(
            res.data.map(list => ({
              id: list.q_uuid,
              creditPolicy: list.credit_policy,
              title: list.title
            }))
          )
        )
      } catch (err: any) { }
    })
  },
  template: {
    set: createAction<ITemplate | null>('FORM_CONSTRUCTOR/TEMPLATE/SET'),

    addStep: createAction<ITemplateStep>('FORM_CONSTRUCTOR/TEMPLATE/ADD_NEW_STEP'),
    editStep: createAction<ITemplateStep>('FORM_CONSTRUCTOR/TEMPLATE/EDIT_STEP'),
    deleteStep: createAction<string>('FORM_CONSTRUCTOR/TEMPLATE/DELETE_STEP'),

    addBlock: createAction<ITemplateBlock>('FORM_CONSTRUCTOR/TEMPLATE/ADD_NEW_BLOCK'),
    editBlock: createAction<ITemplateBlock>('FORM_CONSTRUCTOR/TEMPLATE/EDIT_BLOCK'),
    deleteBlock: createAction<string>('FORM_CONSTRUCTOR/TEMPLATE/DELETE_BLOCK'),

    addInput: createAction<ICustomInput>('FORM_CONSTRUCTOR/TEMPLATE/ADD_NEW_INPUT'),
    editInput: createAction<ICustomInput>('FORM_CONSTRUCTOR/TEMPLATE/EDIT_INPUT'),
    deleteInput: createAction<string>('FORM_CONSTRUCTOR/TEMPLATE/DELETE_INPUT'),

    getAvailableCreditProducts: createAsyncThunk(
      'FORM_CONSTRUCTOR/TEMPLATE/AVAILABLE_CREDIT_PRODUCTS/GET',
      async (_, thunkAPI) => {
        const res = await formConstructorApi.getAvailableCreditProducts()

        if (res.status === 200) {
          thunkAPI.dispatch(constructorActions.template.setAvailableCreditProducts(res.data))
        }
      }
    ),

    setAvailableCreditProducts: createAction<ICreditProduct[]>(
      'FORM_CONSTRUCTOR/TEMPLATE/AVAILABLE_CREDIT_PRODUCTS/SET'
    ),

    get: createAsyncThunk(
      'FORM_CONSTRUCTOR/TEMPLATE/CURRENT/GET',
      async (payload: string, thunkAPI) => {
        const res = await formConstructorApi.getCurrentApplicationTemplate(payload)

        thunkAPI.dispatch(constructorActions.template.set(res.data.elements))
        thunkAPI.dispatch(
          constructorActions.mandatoryQuestions.setMandatoryQuestionsList(res.data.questions)
        )
        thunkAPI.dispatch(
          constructorActions.mandatoryQuestions.setAddedMandatoryQuestionList(res.data.added_questions)
        )

        if (res.data.questions.length > 0) {
          thunkAPI.dispatch(
            setLenderToastMessage({
              type: 'info',
              message: `For this questionnaire, there is a list of mandatory 
              questions (${res.data.questions.length}) that must be included in it`
            })
          )
        }
      }
    ),
    post: createAsyncThunk(
      'FORM_CONSTRUCTOR/TEMPLATE/POST',
      async (payload: ITemplateRequest, thunkAPI) => {
        try {
          const res = await formConstructorApi.postApplicationTemplate(payload)

          if (res.status === 201) {
            thunkAPI.dispatch(
              setLenderToastMessage({
                type: 'success',
                message: 'The template has been created successfully'
              })
            )
            thunkAPI.dispatch(constructorActions.template.getAvailableCreditProducts())
          }
        } catch (err: any) {
          thunkAPI.dispatch(setLenderToastMessage({ type: 'error', message: err.response.data[0] }))
        }
      }
    ),
    update: createAsyncThunk(
      'FORM_CONSTRUCTOR/TEMPLATE/CURRENT/UPDATE',
      async (payload: ITemplateRequest, thunkAPI) => {
        try {
          const res = await formConstructorApi.updateCurrentApplicationTemplate(payload)

          if (res.status === 200) {
            thunkAPI.dispatch(
              setLenderToastMessage({
                type: 'success',
                message: 'The template has been updated successfully'
              })
            )
          }
        } catch (err: any) {
          thunkAPI.dispatch(setLenderToastMessage({ type: 'error', message: err.response.data[0] }))
        }
      }
    ),
    delete: createAsyncThunk(
      'FORM_CONSTRUCTOR/TEMPLATE/CURRENT/DELETE',
      async (payload: string, thunkAPI) => {
        try {
          const res = await formConstructorApi.deleteCurrentApplicationTemplate(payload)

          if (res.status === 204) {
            thunkAPI.dispatch(
              setLenderToastMessage({ type: 'success', message: 'Template deleted successfully' })
            )
            thunkAPI.dispatch(constructorActions.template.getAvailableCreditProducts())
          }
        } catch (e: any) {
          thunkAPI.dispatch(setLenderToastMessage({ type: 'error', message: e.response }))
        }
      }
    )
  },
  mandatoryQuestions: {
    setMandatoryQuestionsList: createAction<IMandatoryQuestions>(
      'FORM_CONSTRUCTOR/MANDATORY_QUESTIONS/LIST/SET'
    ),
    setCurrentMandatoryQuestionToList: createAction<ICustomInput>(
      'FORM_CONSTRUCTOR/MANDATORY_QUESTIONS/CURRENT_TO_LIST/SET'
    ),
    setAddedMandatoryQuestionIdToList: createAction<string>(
      'FORM_CONSTRUCTOR/MANDATORY_QUESTIONS/ADDED_MANDATORY_QUESTIONS/TO_LIST/SET'
    ),
    setAddedMandatoryQuestionList: createAction<string[]>(
      'FORM_CONSTRUCTOR/MANDATORY_QUESTIONS/ADDED_MANDATORY_QUESTIONS/LIST/SET'
    )
  },
  clientApplication: {
    setCurrentStepDataToStorage: createAction<any>(
      'FORM_CONSTRUCTOR/CLIENT_APPLICATION/CURRENT_STEP_DATA/SET'
    ),
    setCurrentStepIndex: createAction<number>(
      'FORM_CONSTRUCTOR/CLIENT_APPLICATION/CURRENT_STEP_INDEX/SET'
    ),
    setBETemplateID: createAction<number>('FORM_CONSTRUCTOR/CLIENT_APPLICATION/BE_TEMPLATE_ID'),
    getCurrentStep: createAsyncThunk(
      'FORM_CONSTRUCTOR/CLIENT_APPLICATION/CURRENT_STEP/GET',
      async (payload: { stepId: string; templateBEId: number }, thunkAPI) => {
        try {
          const res = await formConstructorApi.getCurrentApplicationsStep(payload)

          if (res.status === 200) {
            thunkAPI.dispatch(
              constructorActions.clientApplication.setCurrentStepDataToStorage({
                ...res.data.elements,
                step_doc: res.data.step_doc
              })
            )
          }
        } catch (e: any) {
          thunkAPI.dispatch(constructorActions.clientApplication.setCurrentStepDataToStorage(null))
        }
      }
    ),
    postCurrentStep: createAsyncThunk(
      'FORM_CONSTRUCTOR/CLIENT_APPLICATION/CURRENT_STEP/POST',
      async (payload: ICurrentTemplatesStep, thunkAPI) => {
        const {
          constructors,
          client: {
            all: {
              applicationForm: { shopRequestData }
            }
          },
          auth: {
            userFlow: {
              appFormFlowData: {
                flow_data: { step, id: appId }
              }
            }
          }
        }: any = thunkAPI.getState()

        const { isFormEdited, reset, stepFiles, step_uuid, questionnaire, elements } = payload
        const { currentStepIndex } = constructors.clientApplication
        const currentStepsLength = constructors.template.steps.length
        const creditType = shopRequestData?.credit_policy.credit_type
        const filesToUpload = stepFiles
          ? Object.entries(stepFiles).filter(
            ([_, fileData]) => fileData && typeof fileData.file !== 'string'
          )
          : []

        try {
          if (isFormEdited || filesToUpload.length) {
            const stepRes = await formConstructorApi.postCurrentApplicationsStep({
              step_uuid,
              questionnaire,
              elements
            })

            if (stepRes.status === 200 || stepRes.status === 201) {
              if (filesToUpload) {
                function findObjectById (obj: any, targetId: string): string | null {
                  for (const prop in obj) {
                    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
                      if (prop === 'id' && obj[prop] === targetId) {
                        return obj?.question
                      }
                      if (typeof obj[prop] === 'object') {
                        const result = findObjectById(obj[prop], targetId)
                        if (result !== null) {
                          return result
                        }
                      }
                    }
                  }
                  return null
                }

                const filesRequests = filesToUpload.map(([_, fileData]) => {
                  const fileFormData = new FormData()
                  for (const [key, value] of Object.entries(fileData)) {
                    fileFormData.append(key, value)
                  }

                  const findIdInput = JSON.parse(fileData?.elements)?.inputId

                  const findFileName = findObjectById(constructors.template, findIdInput)

                  if (findFileName) {
                    fileFormData.append('question_name', findFileName)
                  }

                  // fileFormData.append('step_uuid', step_uuid)
                  // fileFormData.append('q_id', `${questionnaire}`)
                  return formConstructorApi.postCurrentAppStepFile(appId, fileFormData)
                })

                try {
                  await Promise.all(filesRequests)

                  thunkAPI.dispatch(
                    setClientToastMessage({ type: 'success', message: 'Successfully' })
                  )
                  if (currentStepsLength > currentStepIndex + 1) {
                    thunkAPI.dispatch(
                      constructorActions.clientApplication.setCurrentStepIndex(currentStepIndex + 1)
                    )
                  } else {
                    // @ts-ignore
                    thunkAPI.dispatch(updateAppFormUserFlowThunk({ step: step + 1 }))

                    if (creditType === 'Consumer finance') {
                      clientApi.applicationForm.changeStatus(appId, 4)
                    }
                  }
                  if (reset) reset()
                } catch (err: any) {
                  thunkAPI.dispatch(
                    setClientToastMessage({ type: 'error', message: 'Error when upload files' })
                  )
                }
              } else {
                thunkAPI.dispatch(
                  setClientToastMessage({ type: 'success', message: 'Successfully' })
                )
                if (currentStepsLength > currentStepIndex + 1) {
                  thunkAPI.dispatch(
                    constructorActions.clientApplication.setCurrentStepIndex(currentStepIndex + 1)
                  )
                } else {
                  // @ts-ignore
                  thunkAPI.dispatch(updateAppFormUserFlowThunk({ step: step + 1 }))

                  if (creditType === 'Consumer finance') {
                    clientApi.applicationForm.changeStatus(appId, 4)
                  }
                }

                if (reset) reset()
              }
            }
          } else {
            if (currentStepsLength > currentStepIndex + 1) {
              thunkAPI.dispatch(
                constructorActions.clientApplication.setCurrentStepIndex(currentStepIndex + 1)
              )
            } else {
              // @ts-ignore
              thunkAPI.dispatch(updateAppFormUserFlowThunk({ step: step + 1 }))

              if (creditType === 'Consumer finance') {
                clientApi.applicationForm.changeStatus(appId, 4)
              }
            }
          }
        } catch (e: any) {
          thunkAPI.dispatch(setClientToastMessage({ type: 'error', message: e.response.data }))
        }
      }
    ),
    updateCurrentUserTemplate: createAsyncThunk(
      'FORM_CONSTRUCTOR/CLIENT_APPLICATION/CURRENT_USER_TEMPLATE_UPDATE/PUT',
      async (payload: IClientTemplateUpdateData) =>
        await formConstructorApi.updateCurrentUserTemplate(payload)
    ),
    getClientApplication: createAsyncThunk(
      'FORM_CONSTRUCTOR/CLIENT_APPLICATION/APPLICATION_GET',
      async (_, thunkAPI) => {
        try {
          const res = await formConstructorApi.getClientApplication()
          thunkAPI.dispatch(constructorActions.clientApplication.setBETemplateID(res.data[0].id))

          thunkAPI.dispatch(setAppFormTemplateType(res.data[0].selected_template.q_type))
          if (res.data[0].filled_template) {
            thunkAPI.dispatch(constructorActions.template.set(res.data[0].filled_template))
          } else {
            thunkAPI.dispatch(
              constructorActions.template.set(res.data[0].selected_template.elements)
            )
          }
        } catch (e: any) {
          thunkAPI.dispatch(setClientToastMessage({ type: 'error', message: e.response.data }))
        }
      }
    ),
    setClientsMandatoryQuestionsList: createAction<string>(
      'FORM_CONSTRUCTOR/CLIENT_APPLICATION/CLIENTS_MANDATORY_QUESTIONS_LIST/SET'
    ),
    postFilledMandatoryQuestions: createAsyncThunk(
      'FORM_CONSTRUCTOR/CLIENT_APPLICATION/CLIENTS_MANDATORY_QUESTIONS/POST',
      async (data: { questionnaire: number; answered_on_questions: Object }) =>
        await formConstructorApi.postFilledMandatoryQuestions(data)
    ),
    deleteStepFile: createAsyncThunk(
      'FORM_CONSTRUCTOR/CLIENT_APPLICATION/STEP_FILE/DELETE',
      async (payload: { step_uuid: string; q_id: number; doc_id: string }, { dispatch }) => {
        try {
          const res = await formConstructorApi.deleteStepFile(payload)

          if (res.status === 200) {
            dispatch(
              constructorActions.clientApplication.setCurrentStepDataToStorage({
                ...res.data.elements,
                step_doc: res.data.step_doc
              })
            )
            return true
          }
        } catch (err: any) {
          dispatch(
            setClientToastMessage({ type: 'error', message: getErrorMessage(err.response.data) })
          )
          return false
        }
      }
    ),
    getStepFile: createAsyncThunk(
      'FORM_CONSTRUCTOR/CLIENT_APPLICATION/STEP_FILE/GET',
      async (doc_id: number, { dispatch }) => {
        try {
          const res = await formConstructorApi.getStepFile(doc_id)

          if (res.status === 200) {
            return res.data
          }
        } catch (err: any) {
          dispatch(
            setClientToastMessage({ type: 'error', message: getErrorMessage(err.response.data) })
          )
          return false
        }
      }
    )
  },
  guarantorApplication: {
    getGuarantorApplication: createAsyncThunk(
      'FORM_CONSTRUCTOR/GUARANTOR/APPLICATION_GET',
      async (_, thunkAPI) => {
        const res: any = { data: mockResponse }
        thunkAPI.dispatch(constructorActions.template.set(res.data[0].selected_template.elements))
      }
    ),
    setCurrentStepIndex: createAction<number>(
      'FORM_CONSTRUCTOR/GUARANTOR/CURRENT_STEP_INDEX/SET'
    )
  }
}
