import { Box, Button, FormControlLabel, ListItemIcon, TextField, Tooltip, Typography } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import React, { useCallback, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import removeTableIcon from 'assets/images/icons/remove-table-icon.svg'
import FormErrorMessage from 'components/common/formErrorMessage/formErrorMessage'
import { currentUserSelector, userFlowSelector } from 'redux/reducers/auth/authSelectors'
import { updateAppFormUserFlowThunk } from 'redux/reducers/auth/authThunks'
import { appFormCreditPolicyData } from 'redux/reducers/client/allState/clientSelectors'
import { postApplicationByIdGuarantorInvitations } from 'redux/reducers/client/allState/clientThunks'

interface IFormProps {
  emails: { email: string }[]
}

const InvitationGuarantor = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { appFormFlowData } = useSelector(userFlowSelector)
  const creditPolicy = useSelector(appFormCreditPolicyData)
  const currentUser = useSelector(currentUserSelector)

  const [check, setCheck] = useState(false)

  const { register, handleSubmit, watch, control, formState: { errors } } = useForm<IFormProps>({
    mode: 'onChange',
    defaultValues: {
      emails: Array.from({ length: creditPolicy?.required_guarantors_min || 1 }, () => ({ email: '' }))
    }
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'emails'
  })

  const handlePrev = useCallback(() => {
    if (appFormFlowData) {
      dispatch(updateAppFormUserFlowThunk({ step: appFormFlowData.flow_data.step - 1 }))
    }
  }, [appFormFlowData, dispatch])

  const addGuarantorEmail = () => {
    if (creditPolicy) {
      if (fields.length < creditPolicy.required_guarantors_min) {
        for (let i = 0; i < creditPolicy.required_guarantors_min; i++) {
          append({ email: '' })
        }
      } else {
        append({ email: '' })
      }
    }
  }

  if (!appFormFlowData || !creditPolicy || !currentUser) return <Typography>{t('client.applicationForm.bankCard.noData')}</Typography>

  const {
    flow_data: { id: appId, step }
  } = appFormFlowData

  const onSubmit = async (data: IFormProps) => {
    if (data) {
      data.emails.map(async ({ email }: { email: string }) => {
        await postApplicationByIdGuarantorInvitations(appId, email)
      })

      if (appFormFlowData) {
        dispatch(updateAppFormUserFlowThunk({ step: step + 1 }))
      }
    }
  }

  const validateNoDuplicateEmails = (email: string, index: number) => {
    const otherEmails = fields
      .map((field, i) => watch(`emails.${i}.email`)?.toLowerCase())
      .filter((_, i) => i !== index)
    if (otherEmails.includes(email?.toLowerCase())) {
      return t('formValidation.emailAlreadyUsed')
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography fontSize={24} textAlign="center" fontWeight="bold" mb={4}>
        {t('client.applicationForm.invitationOfTheGuarantor.title', { min: creditPolicy.required_guarantors_min, max: creditPolicy.required_guarantors_max })}
      </Typography>
      <Box mb={4} sx={{ display: 'flex', alignItems: 'center', gap: '16px', flexWrap: 'wrap' }}>
        {fields.map((item, index) => (
          <Box key={`email-guarantor-${item.id}`} sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
            <Box>
              <TextField
                variant="standard"
                label={t('client.applicationForm.invitationOfTheGuarantor.label.email')}
                {...register(`emails.${index}.email`, {
                  required: t('formValidation.required'),
                  validate: {
                    currentUserEmail: email => {
                      if (currentUser.email.toLowerCase() === email.toLowerCase()) {
                        return t('formValidation.itsYou')
                      }
                    },
                    checkDuplicate: (email) => validateNoDuplicateEmails(email, index)
                  }
                })}
                type="email"
              />
              <FormErrorMessage errors={errors} name={`emails[${index}].email`} />
            </Box>
            {fields.length > creditPolicy.required_guarantors_min && (
              <Tooltip title={t('common.buttons.remove')}>
                <ListItemIcon sx={{ minWidth: '20px', cursor: 'pointer' }}>
                  <img src={removeTableIcon} onClick={() => remove(index)} alt="Remove Icon" />
                </ListItemIcon>
              </Tooltip>
            )}
          </Box>
        ))}
      </Box>
      <Box mb={4}>
        <Button
          type="button"
          onClick={addGuarantorEmail}
          disabled={fields.length >= creditPolicy.required_guarantors_max}
        >
          {t('common.buttons.add')}
        </Button>
      </Box>
      <FormControlLabel
        control={<Checkbox
          checked={check}
          onChange={() => setCheck(prev => !prev)}
        />}
        label={t('client.applicationForm.invitationOfTheGuarantor.label.desc')}
        sx={{ mb: 4 }}
      />
      <Box>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: 2
        }}>
          <Button onClick={handlePrev} variant="contained">
            {t('client.applicationForm.bankCard.buttonPrev')}
          </Button>
          <Button type="submit" variant="contained" disabled={!check}>
            {t('common.buttons.save')}
          </Button>
        </Box>
      </Box>
    </form>
  )
}

export default InvitationGuarantor
