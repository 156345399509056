export const getDeviceType = () => {
  const userAgent = navigator.userAgent || navigator.vendor

  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return 'iOS'
  }

  if (/android/i.test(userAgent)) {
    return 'Android'
  }

  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone'
  }

  if (/tablet|ipad|playbook|silk/i.test(userAgent)) {
    return 'Tablet'
  }

  if (/windows|mac os|linux/i.test(userAgent)) {
    return 'PC'
  }

  return 'Unknown'
}
